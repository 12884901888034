<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div class="full-page-bg-color" slot="no-body">
          <div class="vx-row no-gutter">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center" >
              <img alt="login" class="mx-auto" src="@/assets/images/pages/lock-screen.png">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Authorizing {{ client_name || client_id }}</h4>
                </div>
                <vs-input class="w-full mb-6" disabled icon="icon icon-lock" icon-no-border icon-pack="feather"
                          v-model="client_id"/>
                <vs-input class="w-full mb-6" disabled icon="icon icon-user" icon-no-border icon-pack="feather"
                          v-model="scope"/>

                <div class="flex justify-between flex-wrap">
                  <a @click="logoutAndLogin" class="mb-4" href="#">Are you not {{ activeUserInfo.full_name }}?</a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      client_name: this.$route.query.client_name,
      client_id: this.$route.query.client_id || '',
      state: this.$route.query.state || '',
      scope: this.$route.query.scope || '',
      redirect_uri: this.$route.query.redirect_uri || '',
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    logoutAndLogin() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userInfo')
      this.$router.push('/login')
    },
    cancelAttempt() {
      location.href = this.redirect_uri;
    },
    async authorizeApplication() {

      const {client_id, scope, state, redirect_uri} = this.$route.query;

      const URL_PARAMS = new URLSearchParams({
        response_type: 'code',
        client_id,
        scope,
        state,
        redirect_uri,
      });

      this.$http
        .get(`oauth2/authorize?${URL_PARAMS}`, {
          maxRedirects: 0,
        })
        .then(response => {

          this.$gtag.event('auth_oauth_success', {
            event_category: 'authentication',
            event_label: 'auth_oauth_success',
          });

          if (response.data.redirect) {
            location.href = response.data.redirect;
            return;
          }

          this.redirectToCancel();
        })
        .catch(() => {

          this.$gtag.event('auth_oauth_failure', {
            event_category: 'authentication',
            event_label: 'auth_oauth_failure',
          });

          this.redirectToCancel();
        });

    },
    redirectToLogin() {
      this.$router.push({
        path: 'login',
        query: this.$route.query,
      });
    },
    redirectToCancel() {
      this.$vs.notify({
        title: 'Authorize Failed',
        text: 'Failed to authorize the new client. This error has been logged.',
        color: 'warning',
      });

      location.href = `${this.redirect_uri}?intent=cancel`;
    },
  },
  created() {

    if (!this.$store.state.auth.isUserLoggedIn()) {
      return this.redirectToLogin();
    }

    this.authorizeApplication();
  },
};
</script>
